/*
$accent: #009988;
$body-bg: #101010;
$body-color: #bbbbbb;
$alt-bg: #202020;
$alt-color: #f2f2f2;

#f7f7f9

$body-bg: #f2f2f2;
*/

$body-bg: #ffffff;

$detail-bg: #f8f9fa;

$bg-light: #f2f2f2;

$bg-color: #183153;
$bg-color2: #005086;
$bg-color-light: #264e87;

$link-color: #f2f2f2;

$border-light: #e2e2e2;

$disabled-color: #5e5e5e;

$table-highlight-color: #3fc26b;
$table-hover-color: #cefcb0;

/*
#CFE8FC;
*/



/* #012169; */

$accent: #009988;

$body-color: #3d3d3d;
$alt-bg: #2b2b2b;

$alt-color: #f2f2f2;


$form-grey: #ced4da;
$form-green: #C5EDD2;

$border-grey: #dbe3eb;

/*
$primary: #28a745;

$primary: #44AA00;

*/


$primary: #338000;

$danger: #dc3545;


/* Chart */
$chartBorderColor: #3fc26b;
$chartBackgroundColor: #C5EDD2;

.menu-bg {
  color: $alt-bg;
}

.accent-color {
  color: $accent;
}

/*This is so we can offset the modals*/
$side-menu-width: 200px;

.side-menu-offset {
  margin-right: -$side-menu-width;
}
