/* You can add global styles to this file, and also import other style files */

@import './src/app/styles/_colors';

//@import './node_modules/bootstrap-scss/bootstrap';

/*
@import "./node_modules/bootstrap-scss/_functions";
@import "./node_modules/bootstrap-scss/_variables";
@import "./node_modules/bootstrap-scss/_mixins";
@import "./node_modules/bootstrap-scss/_root";
@import "./node_modules/bootstrap-scss/_reboot";
@import "./node_modules/bootstrap-scss/_type";
@import "./node_modules/bootstrap-scss/_images";
@import "./node_modules/bootstrap-scss/_code";
@import "./node_modules/bootstrap-scss/_grid";
@import "./node_modules/bootstrap-scss/_tables";
@import "./node_modules/bootstrap-scss/_forms";
@import "./node_modules/bootstrap-scss/_buttons";
@import "./node_modules/bootstrap-scss/_transitions";
@import "./node_modules/bootstrap-scss/_dropdown";
@import "./node_modules/bootstrap-scss/_button-group";
@import "./node_modules/bootstrap-scss/_input-group";
@import "./node_modules/bootstrap-scss/_custom-forms";
@import "./node_modules/bootstrap-scss/_nav";
@import "./node_modules/bootstrap-scss/_navbar";
@import "./node_modules/bootstrap-scss/_card";
@import "./node_modules/bootstrap-scss/_breadcrumb";
@import "./node_modules/bootstrap-scss/_pagination";
@import "./node_modules/bootstrap-scss/_badge";
@import "./node_modules/bootstrap-scss/_jumbotron";
@import "./node_modules/bootstrap-scss/_alert";
@import "./node_modules/bootstrap-scss/_progress";
@import "./node_modules/bootstrap-scss/_media";
@import "./node_modules/bootstrap-scss/_list-group";
@import "./node_modules/bootstrap-scss/_close";
@import "./node_modules/bootstrap-scss/_toasts";
@import "./node_modules/bootstrap-scss/_modal";
@import "./node_modules/bootstrap-scss/_tooltip";
@import "./node_modules/bootstrap-scss/_popover";
@import "./node_modules/bootstrap-scss/_carousel";
@import "./node_modules/bootstrap-scss/_spinners";
@import "./node_modules/bootstrap-scss/_utilities";
@import "./node_modules/bootstrap-scss/_print";
*/

/*
html, body {
  height: 100%;
}
*/

html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  }


@import './src/app/styles/site';

/* Importing Bootstrap SCSS file. */
@import './node_modules/bootstrap/scss/bootstrap.scss';
