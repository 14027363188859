@import "./src/app/styles/_colors";

html {
  /* Base Font Size */
  font-size: 14px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif !important;
  background-color: $bg-light !important;
  /*
  background-image: url("/assets/images/lightbackground.jpg");
  background-repeat: repeat;
  */
}


.page-title {
  font-size: 1.5em;
  font-weight: bold;
}

.table {
  font-size: 0.9em;
}

/*
.table thead th a {
  color: black;
  text-decoration: none;
}
*/

.table tbody td a {
  color: $body-color;
}

.table thead > tr > th {
  border-bottom: none !important;
}

.table tbody {
  border-top: solid 0.9px grey !important;
}

td { cursor: pointer; }

label {
  font-size: 1em;
}

input.form-control {
  /*font-size: 1em;*/
}

select.form-select {
  font-size: 1em;
}

.pagination-details {
  margin-left: 5px;
  font-size: 0.9em;
}

.site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-content {
  flex: 1;
}

.main-content {
  margin-top: 0px;
}

.row {
  .skill-block {
    padding: 2.5rem 1.25rem;
    margin-bottom: 1rem;
  }
}

.portfolio-preview {
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 4rem;

  img {
    border: 2px solid $body-color;
  }
}

.filter-card {
  .mat-card-header-text {
    margin: 0rem;
  }
}

.text-muted {
  color: #6c757d !important;

  a {
    color: $bg-color2 !important;
  }
}

.text-muted-light {
  color: #f7f7f9 !important;
}

.w-1 {
  width: 1% !important;
}

.w-2 {
  width: 2% !important;
}

.w-3 {
  width: 3% !important;
}

.w-4 {
  width: 4% !important;
}

.w-5 {
  width: 5% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 7% !important;
}

.w-8 {
  width: 8% !important;
}

.w-9 {
  width: 9% !important;
}

.w-10 {
  width: 10% !important;
}

.w-11 {
  width: 11% !important;
}

.w-12 {
  width: 12% !important;
}

.w-13 {
  width: 13% !important;
}

.w-14 {
  width: 14% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

/* Font Size */

.fs-7 {
  font-size: 0.9rem !important;
}

.fs-8 {
  font-size: 0.8rem !important;
}

/*  List */
.list-container {

  min-width: 100% !important;

  /*
  width: 100%;

  margin-top: 2%;
  */
}

.list-content {
  width: 100%;
  display: flex;
  align-content: center;
}

.list-heading {
  flex-direction: row;
  justify-content: space-between;
}

#searchTextBox {
  width: 500px;
}

.pagination {
  display: flex;
  justify-content: flex-end;

  padding-left: 10px;

  a.page-link {
    color: $body-color;
  }

  .pagination-details {
    padding-left: 8px;
  }
}

.table-section {
  padding-top: 10px;
}

.btn-primary {
  color: white !important;
  background-color: $primary;
  border-color: $primary;
}

.btn-danger {
  color: white !important;
  background-color: $danger;
  border-color: $danger;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: white !important;
  background-color: $primary;
  border-color: $primary;
}

.rounded-lg {
  border-radius: 0.8rem !important;
}

.detail {
  background: $detail-bg;
}

.title-heading {
  font-weight: bold;
  font-size: 1.5em;
  background: white;
  padding: 5px 8px;
  border-radius: 15px;
}

.section-heading {
  padding-top: 3px;
  font-weight: bold;
  font-size: 1.1em;
}

.metrics-title {
  font-size: 1.3em;
  font-weight: 400;
}


.btn-round {
  border-radius: 50% !important;
  line-height: 1em !important;
  width: 28px !important;
  height: 28px !important;
  display: flex;
  justify-content: center;

  text-align:center;
  padding-left: 0px;
  padding-right: 0px;

  /*
  color: white !important;
  background: $primary;
  */
  .fa-plus {
    margin-top: 0px;
    margin-left: 0px;
  }
}

.btn.btn-primary[disabled] {
  background-color: $disabled-color;
  border-color: #141619;
}

.btn.btn-danger[disabled] {
  background-color: $disabled-color;
  border-color: #141619;
}

.center {
  text-align:center;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.icon-image {
  font-size: 3em;
  padding-right: 20px;
}

li {
  line-height: 1.5em;
  /*
  padding-bottom: 12px;
  */
}


.red {
  color: red;
}

.green {
  color: green;
}

a.customer_event {
  font-weight: bold;
  /* color: red !important; */
}

.table a {
  text-decoration: none;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: $table-hover-color;
  color: black;
}

.table a:hover {
  text-decoration: underline;
}

.modal-footer {
  padding: 0.75em 0em !important;
}

/* Toast */

.toast-success {
  color: #0f5132 !important;
  background-color: #d1e7dd!important;
  border-color: #badbcc!important;
}

.toast-info {
  color: #055160 !important;
  background-color: #cff4fc !important;
  border-color: #b6effb !important;
}

.toast-warning {
  color: #664d03 !important;
  background-color: #fff3cd !important;
  border-color: #ffecb5 !important;
}

.toast-danger {
  color: #842029 !important;
  background-color: #f8d7da !important;
  border-color: #f5c2c7 !important;
}

.toast-primary {
  color: #084298 !important;
  background-color: #cfe2ff !important;
  border-color: #b6d4fe !important;
}

.toast-secondary {
  color: #41464b !important;
  background-color: #e2e3e5 !important;
  border-color: #d3d6d8 !important;
}

.toast-light {
  color: #636464 !important;
  background-color: #fefefe !important;
  border-color: #fdfdfe !important;
}

.toast-dark {
  color: #141619 !important;
  background-color: #d3d3d4 !important;
  border-color: #bcbebf !important;
}


.validation-message {
  color: red;
  font-size: 0.7em;
}

.cc-btn   {
  text-decoration: none !important;
  a:link { text-decoration: none !important; }
  a:visited { text-decoration: none !important; }
  a:hover { text-decoration: none !important; }
  a:active { text-decoration: none !important; }
}

/* Offset for the modals to allow for the sidemenu */
.modal {
    left: 90px !important;
}


/* Page Headings */
.page-section {
  line-height: 1.6em;
}

/*
h1 {
  font-size: 1.8rem !important;
}

h2 {
  font-size: 1.6rem !important;
}
*/

.form-header {
  font-size: 1.8rem !important;
}


/* Pagination */

.pagination {
  padding-right: 5px !important;

  .page-item.active .page-link {
    color: #fff !important;
  }

  li {
    line-height: 1.2em;
    font-size: 0.9em !important;
  }
}

/* User Style Override */
input:-webkit-autofill, input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
}

/* To adjust the add button plus icon */
.add-button {
  padding-right: 2px;
}

tr > th {
  background-color: black !important;
  color: white !important;
  font-weight: normal;
}
